import { Plugin } from 'ckeditor5/src/core';
import { EMPHASIS } from '../utils';
import EmphasisCommand from './command';
/**
 * 文本强调装饰符位置视图与模型的相互转换
 */
export default class EmphasisEditing extends Plugin {
    constructor(editor) {
        super(editor);
        editor.conversion.attributeToElement({
            model: EMPHASIS,
            view: {
                name: 'span',
                styles: {
                    'text-emphasis': 'dot',
                    'text-emphasis-position': 'under',
                    '-webkit-text-emphasis': 'dot',
                    '-webkit-text-emphasis-position': 'under'
                }
            },
            upcastAlso: {
                styles: {
                    'font-emphasize': /[\s\S]+/
                }
            }
        });
        editor.commands.add(EMPHASIS, new EmphasisCommand(editor, EMPHASIS));
        // 定义插件的使用的数据模型
        editor.model.schema.extend('$text', { allowAttributes: EMPHASIS });
        editor.model.schema.setAttributeProperties(EMPHASIS, {
            isFormatting: true,
            copyOnEnter: true
        });
    }
}
