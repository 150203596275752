import { Plugin } from 'ckeditor5/src/core';
import { ButtonView } from 'ckeditor5/src/ui';
import { EMPHASIS } from '../utils';
import icon from '../theme/icons/ellipsis.svg';
// 负责ui的工具栏按钮
export default class EmphasisUI extends Plugin {
    constructor(editor) {
        super(editor);
        this.set('isCheck', true);
    }
    init() {
        // 获取编辑器 UI 的实例
        const editor = this.editor;
        const command = editor.commands.get(EMPHASIS);
        if (!command)
            return;
        // 在工具栏上绘制一个按钮
        editor.ui.componentFactory.add(EMPHASIS, () => {
            // 创建一个按钮
            const button = new ButtonView();
            button.set({
                label: '着重号',
                icon,
                tooltip: true
            });
            // 注册 isEnabled 可观察对象
            button.bind('isEnabled').to(command);
            button
                .bind('isOn')
                .to(command, 'value', () => (this.isCheck = !this.isCheck));
            this.listenTo(button, 'execute', () => {
                editor.execute(EMPHASIS, this.isCheck);
                editor.editing.view.focus();
            });
            return button;
        });
    }
}
