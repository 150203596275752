import { Command } from 'ckeditor5/src/core';
/**
 * 文本强调装饰符的基础命令组件
 * {@link https://developer.mozilla.org/zh-CN/docs/Web/CSS/text-decoration}
 *
 *  给字体添加着重号 editor.execute('emphasis', { value: 'underline dotted' })
 *
 * **注意**：使用 `null` 值执行命令会从模型中删除属性。
 */
// TODO 目前只实现点，根据 mdn 的描述，此样式可以实现各种类型的文本修饰符
export default class EmphasisCommand extends Command {
    /**
     * 创建着重号命令的实例
     * @param {module:core/editor/editor~Edito} editor 编辑器的实例
     * @param {String} attributeKey 着重号的唯一标识
     */
    constructor(editor, attributeKey) {
        super(editor);
        this.attributeKey = attributeKey;
    }
    /**
     * 当数据模型/视图发生更改的时触发
     *  在此处实现着重号工具栏按钮的选中状态
     */
    refresh() {
        // 获取当前数据模型对应的dom
        const model = this.editor.model;
        const doc = model.document;
        // 获取当前选区中的值
        this.value = doc.selection.getAttribute(this.attributeKey);
        // 当前选区是否开始着重号功能
        this.isEnabled = model.schema.checkAttributeInSelection(doc.selection, this.attributeKey);
    }
    /**
     * 执行命令，将传递过来的 value 根据 {@link #attributeKey} 应用到这个选区中
     * 如果 value 的值是空，则会在选区中删除属性
     *
     * @protected
     * @param {Object} [options] 执行命令的对象
     * @param {String} [options.value] 要应用的值
     * @fires execute
     */
    execute() {
        const model = this.editor.model;
        const document = model.document;
        const selection = document.selection;
        const value = !this.value;
        model.change(writer => {
            /**
             * 选区是否折叠 所选区域内容的起点和终点是否在统一位置
             * {@link https://developer.mozilla.org/en-US/docs/Web/API/Selection/isCollapsed}
             */
            if (selection.isCollapsed) {
                if (value) {
                    writer.setSelectionAttribute(this.attributeKey, value);
                }
                else {
                    writer.removeSelectionAttribute(this.attributeKey);
                }
            }
            else {
                // 如果选区非折叠，则更改范围内节点的属性
                const ranges = model.schema.getValidRanges(selection.getRanges(), this.attributeKey);
                for (const range of ranges) {
                    if (value) {
                        writer.setAttribute(this.attributeKey, value, range);
                    }
                    else {
                        writer.removeAttribute(this.attributeKey, range);
                    }
                }
            }
        });
    }
}
