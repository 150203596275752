import { ClassicEditor } from '@ckeditor/ckeditor5-editor-classic';
import { Alignment } from '@ckeditor/ckeditor5-alignment';
import { Autosave } from '@ckeditor/ckeditor5-autosave';
import { Bold, Code, Italic, Strikethrough, Subscript, Superscript, Underline} from '@ckeditor/ckeditor5-basic-styles';
import { BlockQuote } from '@ckeditor/ckeditor5-block-quote';
import { CloudServices } from '@ckeditor/ckeditor5-cloud-services';
import { CodeBlock } from '@ckeditor/ckeditor5-code-block';
import type { EditorConfig } from '@ckeditor/ckeditor5-core';
import { Essentials } from '@ckeditor/ckeditor5-essentials';
import { FontBackgroundColor, FontColor, FontFamily, FontSize } from '@ckeditor/ckeditor5-font';
import { Heading } from '@ckeditor/ckeditor5-heading';
import { Highlight } from '@ckeditor/ckeditor5-highlight';
import { HorizontalLine } from '@ckeditor/ckeditor5-horizontal-line';
import { GeneralHtmlSupport } from '@ckeditor/ckeditor5-html-support';
import { AutoImage,	Image, ImageCaption, ImageInsert, ImageResize, ImageStyle, ImageToolbar, ImageUpload} from '@ckeditor/ckeditor5-image';
import { Indent, IndentBlock } from '@ckeditor/ckeditor5-indent';
import { AutoLink, Link, LinkImage } from '@ckeditor/ckeditor5-link';
import { List, ListProperties } from '@ckeditor/ckeditor5-list';
import { PageBreak } from '@ckeditor/ckeditor5-page-break';
import { Paragraph } from '@ckeditor/ckeditor5-paragraph';
import { RemoveFormat } from '@ckeditor/ckeditor5-remove-format';
import { StandardEditingMode } from '@ckeditor/ckeditor5-restricted-editing';
import { SelectAll } from '@ckeditor/ckeditor5-select-all';
import { SourceEditing } from '@ckeditor/ckeditor5-source-editing';
import { SpecialCharacters,	SpecialCharactersArrows, SpecialCharactersCurrency,	SpecialCharactersEssentials, SpecialCharactersLatin, SpecialCharactersMathematical,SpecialCharactersText} from '@ckeditor/ckeditor5-special-characters';
import { Style } from '@ckeditor/ckeditor5-style';
import { Table,	TableCaption, TableCellProperties, TableColumnResize, TableProperties, TableToolbar} from '@ckeditor/ckeditor5-table';
import { TextTransformation } from '@ckeditor/ckeditor5-typing';
import { Emphasis } from './plugins/emphasis/index';
// import { Base64UploadAdapter, SimpleUploadAdapter } from './plugins/upload/src/index';
import { Base64UploadAdapter, SimpleUploadAdapter } from '@ckeditor/ckeditor5-upload';

class Editor extends ClassicEditor {
	public static override builtinPlugins = [
		Emphasis,
		Alignment,
		AutoImage,
		AutoLink,
		Autosave,
		BlockQuote,
		Bold,
		CloudServices,
		Code,
		CodeBlock,
		Essentials,
		FontBackgroundColor,
		FontColor,
		FontFamily,
		FontSize,
		GeneralHtmlSupport,
		Heading,
		Highlight,
		HorizontalLine,
		Image,
		ImageCaption,
		ImageInsert,
		ImageResize,
		ImageStyle,
		ImageToolbar,
		ImageUpload,
		Indent,
		IndentBlock,
		Italic,
		Link,
		LinkImage,
		List,
		ListProperties,
		PageBreak,
		Paragraph,
		RemoveFormat,
		SelectAll,
		SourceEditing,
		SpecialCharacters,
		SpecialCharactersArrows,
		SpecialCharactersCurrency,
		SpecialCharactersEssentials,
		SpecialCharactersLatin,
		SpecialCharactersMathematical,
		SpecialCharactersText,
		StandardEditingMode,
		Strikethrough,
		Style,
		Subscript,
		Superscript,
		Table,
		TableCaption,
		TableCellProperties,
		TableColumnResize,
		TableProperties,
		TableToolbar,
		TextTransformation,
		Underline,
		Base64UploadAdapter, 
		SimpleUploadAdapter
	];

	public static override defaultConfig: EditorConfig = {
		toolbar: {
			// items: [
			// 	'undo', 'redo','selectAll',
			// 	'|', 'heading',
			// 	'|', 'fontfamily', 'fontsize', 'fontColor', 'fontBackgroundColor',
			// 	'|', 'bold', 'italic','underline', 'strikethrough','emphasis','subscript', 'superscript', 'code','removeFormat',
			// 	'|',  'outdent', 'indent','blockQuote', 'alignment','highlight',
			// 	'|', 'horizontalLine','bulletedList', 'numberedList', 'link', 'codeBlock','imageInsert','specialCharacters','pageBreak','insertTable',
			// 	'|', 'sourceEditing'
			// ]
			items: [
				'selectAll','undo', 'redo',
				'|', 'heading',
				'|', 'fontsize', 'fontColor', 'fontBackgroundColor',
				'|', 'bold', 'italic','underline', 'strikethrough','emphasis','subscript', 'superscript', 'code','removeFormat',
				'|',  'outdent', 'indent','blockQuote', 'alignment','highlight',
				'|', 'horizontalLine','bulletedList', 'numberedList', 'link', 'codeBlock','imageInsert','specialCharacters','pageBreak','insertTable',
				'|', 'sourceEditing'
			]
		},
		language: 'zh-cn',
		image: {
			toolbar: [
				'imageTextAlternative',
				'toggleImageCaption',
				'imageStyle:inline',
				'imageStyle:block',
				'imageStyle:side',
				'linkImage'
			]
		},
		table: {
			contentToolbar: [
				'tableColumn',
				'tableRow',
				'mergeTableCells',
				'tableCellProperties',
				'tableProperties'
			]
		}
	};
}

export default Editor;
