import { Plugin } from 'ckeditor5/src/core';
import EmphasisEditing from './editing';
import EmphasisUI from './ui';
/**
 * 文本强调修饰符
 */
export default class Emphasis extends Plugin {
    static get pluginName() {
        return 'Emphasis';
    }
    static get requires() {
        return [EmphasisUI, EmphasisEditing];
    }
}
